<template>
  <div>
    <H1>SUCCESS</H1>
    <subscription-success />
  </div>
</template>

<script>
import SubscriptionSuccess from '@/components/SubscriptionSuccess.vue'

export default {
  name: 'SubscriptionSuccessView',
  components: {
    SubscriptionSuccess,
  },
}
</script>

<template>
  <div>
    <img
      src="{{ sharesaleURL }}"
      width="1"
      height="1"
    >
  </div>
</template>

<script>

export default {
  name: 'SubscriptionSuccess',
  data() {
    return {
      userId: '',
      subscriptionAmount: 0,
      isLoading: true,
    }
  },
  computed: {
    sharesaleURL() {
      return `https://www.shareasale.com/sale.cfm?tracking=${userId}&amount=${subscriptionAmount}&merchantID=129892&transtype=sale`
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search)
    if (params.get('amount')) {
      this.subscriptionAmount = params.get('amount')
    }
    this.getUser()
    setTimeout(() => this.$router.push({ path: '/home' }), 2000)
  },
  methods: {
    getUser() {
      this.$http.get('/user/dashboard')
        .then(response => {
          if (response.data.userId) {
            this.userId = response.data.userId
          }
        })
    },
  },
}

</script>
